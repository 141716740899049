import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { lang, img_url, page_url } from './../lang'
import { FaSearch } from "react-icons/fa";
// import { useNavigate } from 'react-router-dom';
// import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import api from '../api'

SwiperCore.use([Navigation, Pagination]);
export default function Slide(props) {
    const [images, setImages] = useState([]);
    const [search_type, setSearchType] = useState('discovery');
    const [keyword, setKeyword] = useState('');
	// const navigate = useNavigate();
    const styles = props.styles;
    SwiperCore.use([Autoplay]); // start autoplay

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                let article_id = 1;
              const response = await api.get('/gallery/'+article_id);
              setImages(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    const handleSearch = () => {
        // e.preventDefault();
        // if(search_type==='OPAC'){
        //     window.open("https://search.lib.cmu.ac.th/search/?searchtype=Y&searcharg="+keyword, "_blank");
        if(search_type==='discovery'){
            window.open("https://cmul.primo.exlibrisgroup.com/discovery/search?query=any,contains,"+keyword+"&tab=MainLibrary&search_scope=MainLibrary&vid=66CMU_INST:cmul&offset=0", "_blank");
        }else if(search_type==='Worldwide Search & Request'){
            window.open("https://cmu.on.worldcat.org/external-search?queryString="+keyword+"&lang=th&clusterResults=on&stickyFacetsChecked=on", "_blank");
        }else if(search_type==='EDS'){
            window.open("https://discovery.ebsco.com/c/n2jqxu/results?q="+keyword, "_blank");
        }else if(search_type==='PULINET'){
            window.open("https://search.ebscohost.com/login.aspx?bquery="+keyword+"&submit=Search&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=ill&defaultdb=&option=1", "_blank");
        }else if(search_type==='CMU Intellectual Repository'){
            window.open("http://cmuir.cmu.ac.th/simple-search?query="+keyword, "_blank");
        }else if(search_type==='search website'){
            window.location = page_url+"books?keyword="+keyword;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const openPopup = (item) => {
        if(item.link && item.link!==''){
            if(parseInt(item.popup)===1){
                window.open(item.link);
            }else{
                window.location = item.link;
            }
        }
    }

    return (
        <div className="slide-container">
            {/* group items  */}
            <Swiper
                spaceBetween={5}
                slidesPerView={1}
                speed={900}
                autoplay={{ delay: 3000 }}
                pagination={{
                  type: "progressbar",
                }}
                modules={[Pagination]}
                className="slide-swiper"
            >
                {images.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                        <div className={"card "}>
                            <div className={"image"} onClick={e=>openPopup(item)}>
                                <div className={"image-inner"} style={{ backgroundImage: `url('${img_url + item.url}')` }}></div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {(images.length>0)?
            <div className={styles["search-modal"]}>
                <h5>{lang('สืบค้นข้อมูล :','Search :')}</h5>
                <fieldset>
                    <select name="search_type" id="search_type" value={search_type} onChange={e=>setSearchType(e.target.value)}>
                        <option value="discovery">PRIMO</option>
                        <option value="Worldwide Search & Request">Worldwide Search & Request</option>
                        <option value="EDS">EDS</option>
                        <option value="PULINET">PULINET</option>
                        {/* <option value="Digital Collections">Digital Collections</option> */}
                        <option value="CMU Intellectual Repository">CMU Intellectual Repository</option>
                        <option value="search website">{lang('ค้นหาภายในเว็บ','Search in Website')}</option>
                    </select>
                    <input type="text" name="keyword" id="keyword" value={keyword} onChange={e=>setKeyword(e.target.value)} onKeyDown={handleKeyDown} placeholder={lang('ค้นหาหนังสือ อีบุ๊ค วารสาร บทความ วิทยานิพนธ์ และ ILL','Find books, e-Books, Journals, articles, dissertations and ILL')} />
                    <button type="button" onClick={e=>handleSearch()}><FaSearch /> {lang('ค้นหา','Search')}</button>
                    <button className={styles["mobile-input"]} type="button" onClick={e=>handleSearch()}><FaSearch /> {lang('ค้นหา','Search')}</button>
                </fieldset>
            </div>
          :''}
        </div>
    )
}
