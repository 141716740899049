import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom';
import { lang, img_url } from '../components/lang'
import { useLocation } from "react-router-dom";
import api from '../components/api'
// import Ebook from '../components/Homepage/Ebook'
// import Resource from '../components/Homepage/Resource'
// import Slide from '../components/Homepage/Slide'
import styles from '../assets/css/ebook.module.css';

const Menu = lazy(() => import ('../components/Template/Menu'))
const Nav = lazy(() => import ('../components/Template/Nav'))
const BookShelf = lazy(() => import ('../components/EBook/BookShelf'))
const BookCollectionShelf = lazy(() => import ('../components/EBook/BookCollectionShelf'))
const BookDetail = lazy(() => import ('../components/EBook/BookDetail'))

export default function Ebook(props) {
    const [init, setInit] = useState([]);
	  const navigate = useNavigate();
    const [collection, setCollection] = useState(null);
    const [book_type, setBookType] = useState('all');
    const [nav_data, setNavData] = useState([]);
    const [book, setBook] = useState({});
    const [books, setBooks] = useState([]);
    const [filterBooks, setFilterBooks] = useState([]);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    let keyword = (params.get('keyword'))?params.get('keyword'):'';
    let bookid = (params.get('id'))?params.get('id'):0;
    const [book_id, setBookID] = useState(bookid);
    const [filter, setFilter] = useState(keyword);
    const type_params = useParams();
    const collection_id = type_params.collectionID;

    useEffect(()=>{
        let type = 'all';
        if(props.bookType===''){
          if(type_params.bookType){
              type = type_params.bookType;
              setBookType(type_params.bookType);
          }else{
              setBookType('all');
          }
        }else{
          type = props.bookType;
          setBookType(type);
        }
        const fetchInit = async () =>{
          try {
            const response = await api.get('/menu/3');
            setNavData(response.data);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          try {
            const response = await api.get('/book/type/'+type);
            setBooks(current => response.data.results);
            if(keyword!==''){
              let filteredItems = response.data.results.filter(
                    item => item.title.toLowerCase().includes(filter.toLowerCase()) || 
                    item.author.toLowerCase().includes(filter.toLowerCase()) || 
                    item.isbn.toLowerCase().includes(filter.toLowerCase()),
                );
              setFilterBooks(filteredItems);
            }else{
              setFilterBooks(response.data.results);
            }
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        if(collection_id){
            fetchData(collection_id);
        }else{
          fetchInit();
        }
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    const fetchData = async (id) =>{
      try {
        const response = await api.get('/collection/'+id);
        setBooks(current => response.data.all_books);
        if(!collection){
          setCollection(response.data.results);
        }
        if(keyword!==''){
          let filteredItems = response.data.all_books.filter(
                                  item => item.title.toLowerCase().includes(filter.toLowerCase()) || 
                                  item.author.toLowerCase().includes(filter.toLowerCase()) || 
                                  item.isbn.toLowerCase().includes(filter.toLowerCase()),
                              );
          setFilterBooks(filteredItems);
        }else{
          setFilterBooks(response.data.all_books);
        }
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
    }

    useEffect(()=>{
        setBookID(0);
        setBook({});
        const fetchInit = async () =>{
            let menu_id = 3;
            if(book_type==='book'){
              menu_id = 11;
            }else if(book_type==='e-book'){
              menu_id = 11;
            }else if(book_type==='e-journal'){
              menu_id = 13;
            }else if(book_type==='journal'){
              menu_id = 12;
            }
            try {
              const response = await api.get('/menu/'+menu_id);
              setNavData(current => response.data);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
              const response = await api.get('/book/type/'+book_type);
              setBooks(current => response.data.results);
              if(keyword!==''){
                let filteredItems = response.data.results.filter(
                      item => item.title.toLowerCase().includes(filter.toLowerCase()) || 
                      item.author.toLowerCase().includes(filter.toLowerCase()) || 
                      item.isbn.toLowerCase().includes(filter.toLowerCase()),
                  );
                setFilterBooks(filteredItems);
              }else{
                setFilterBooks(response.data.results);
              }
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        if(collection){
          console.log(1);
          fetchData(collection.id);
        }else{
          fetchInit();
        }
    },[book_type,collection]);

    useEffect(()=>{
      setBookID(0);
      setBook({});
      let filteredItems = books.filter(
            item => item.title.toLowerCase().includes(filter.toLowerCase()) || 
            item.author.toLowerCase().includes(filter.toLowerCase()) || 
            item.isbn.toLowerCase().includes(filter.toLowerCase()),
        );
      setFilterBooks(filteredItems);
    },[filter]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }
    
    return (
        <div className={"container "+styles["ebook-container"]}>
              <Nav styles={styles} nav_data={nav_data} collection={collection} />
              <div className={styles["content"]+" reveal"}>
                  <Menu book_type={book_type}  collection={collection} setBookType={setBookType} setCollection={setCollection} setFilter={setFilter} filter={filter} right={true}/>
                  <div className={styles["content-container"]}>
                  {(collection&&parseInt(collection.id)>0)?(
                      <Suspense fallback={<h1>Loading Shelf</h1>}>
                          <BookCollectionShelf styles={styles} data={filterBooks} collection={collection} setBookID={setBookID} setBook={setBook}/>
                      </Suspense>
                  ):(
                    (book_id===0)?(
                      <Suspense fallback={<h1>Loading Shelf</h1>}>
                          <BookShelf styles={styles} data={filterBooks} book_type={book_type} setBookID={setBookID} setBook={setBook}/>
                      </Suspense>
                    ):(
                      <Suspense fallback={<h1>Loading Book</h1>}>
                          <BookDetail styles={styles} data={book} setBookID={setBookID} setBook={setBook}/>
                      </Suspense>
                    )
                  )}
                  </div>
              </div>
        </div>
    )
}