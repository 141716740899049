import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { lang,chk_img } from '../lang'
import api from '../api'
import styles from '../../assets/css/ebook.module.css';

export default function News(props) {
	const navigate = useNavigate();
    let collections = props.collections;

    function showBook(item){
        if(item.book_type==="e-book"||item.book_type==="e-journal"){
          window.open(item.link);
        }else{
          window.location.href = process.env.PUBLIC_URL + "/book/"+item.id;
          // setBookID(item.id);
          // setBook(item);
          // setPage(1);
        }
    }

    return (
        <div className={"container "+styles["home"]}>
            {collections.map((item, key) =>(
                <div key={"item-"+key}>
                    <h2>{lang(item.name,item.name_en)}</h2>
                    <ul className={styles["book-shelf"]}>
                    {item.books.map((data, index) =>(
                    <li key={'ebook-'+index}>
                        <div className={styles["book-shelf-card"]} onClick={e=>showBook(data)}>
                        <div className={styles["cover"]}><img src={chk_img(data.img_url)} alt={lang(data.title,data.title)} /></div>
                        <div className={styles["book-info"]}>
                            <p className={styles["published"]}>{data.published}</p>
                            <p className={styles["title"]}>{data.title}</p>
                            <p className={styles["isbn"]}>ISBN: {data.isbn}</p>
                            <p className={styles["author"]}>{lang('แต่งโดย','Author')}: {data.author}</p>
                        </div>
                        </div>
                    </li>
                    ))}
                    </ul>
                    <div className={styles["readmore"]}>
                        <button onClick={() => navigate('/collection/'+item.id)}>{lang("ดูเพิ่มเติม","View more")}</button></div>
                </div>
            ))}
        </div>
    )
}
